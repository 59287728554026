import React, { useState } from "react";
import ReactDOM from "react-dom";

import "./styles.css";

function App() {
  const [text, setText] = useState("");

  return (
    <div className="App">
      <textarea
        placeholder="Type pinyin. 1-4 for tones"
        style={{
          boxSizing: "border-box",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          padding: "1rem",
          fontSize: "2rem",
          fontFamily: "sans-serif",
          whiteSpace: ""
        }}
        value={text}
        onChange={e => {
          const val = e.currentTarget.value;
          const tone = parseInt(val[val.length - 1], 10);
          if (!(tone >= 1 && tone <= 4)) return setText(val);

          const split = val
            .slice(0, -1)
            .split(/([aeoiu][^\w]*)/i)
            .filter(Boolean);

          let lastIndex = split.length - 1;
          while (true) {
            if (!/[aeoiu]/i.test(split[lastIndex - 1])) {
              break;
            }
            lastIndex--;
          }

          // http://www.pinyin.info/unicode/unicode_test.html
          split[lastIndex] =
            split[lastIndex][0] +
            String.fromCharCode(
              {
                1: 0x0304,
                2: 0x0301,
                3: 0x030c,
                4: 0x0300
              }[tone]
            );

          setText(split.join(""));
        }}
      >
        sdfsf
      </textarea>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
